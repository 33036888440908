import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const SecondPage = () => (
  <Layout>
    <SEO title="Terms and conditions" />
    <div className="mb-16 md:mb-28 text-white bg-helpappy-red  ">
      <div className="container flex flex-col items-center text-center px-8 py-16 mx-auto">
        <Link to="/">
          <StaticImage
            src="../images/logo.png"
            alt="helpappy logo"
            layout="fixed"
            placeholder="blurred"
            width={200}
            quality={100}
            className="mb-12"
          />
        </Link>

        <p className="text-2xl font-bold mt-4 mb-2 md:mb-0">
          Helpappy, your very own life admin PA.
        </p>
        <p className="text-2xl font-bold mb-12">
          You’ll have less to do but more will get done.
        </p>
        <p>
          <a className="text-xl" href="mailto:support@helpappy.com">
            support@helpappy.com
          </a>
        </p>
      </div>
    </div>

    <div className="container mx-auto px-8 mb-16 md:mb-28 md:max-w-6xl">
      <h1 className="font-bold text-2xl mt-4 mb-2">
        HELPAPPY TERMS AND CONDITIONS OF SERVICE
      </h1>
      <h2 className="font-bold text-lg mt-4 mb-2">1. IMPORTANT NOTICE</h2>
      <p className="mb-2">
        We do not require a signed agreement from you. Your use of any services
        or resources provided by us, including this website, denote your
        complete agreement with and acceptance of these terms and conditions.
      </p>

      <h2 className="font-bold text-lg mt-4 mb-2">2. DEFINITIONS</h2>

      <table class="table-auto mt-4 mb-2">
        <tbody>
          <tr className="bg-gray-50 dark:bg-gray-700 ">
            <td className="w-32 font-bold p-2">“Bill day”</td>
            <td className="p-2">
              means 7 days before the monthly anniversary of the original
              signup, upon which further monthly subscription fees will be
              taken. In the event that the original bill day is not available in
              a future month (for example 31st) the fees will be taken on the
              28th of the month. If the bill day falls on a weekend, the fees
              will be taken on the preceding weekday
            </td>
          </tr>
          <tr>
            <td className="w-32 font-bold p-2">“Client” or “You”</td>
            <td className="p-2">
              means the individual or organisation that buys or agrees to buy
              the Services from the Helpappy
            </td>
          </tr>
          <tr className="bg-gray-50 dark:bg-gray-700 ">
            <td className="w-32 font-bold p-2">“Consumer” “Helpappy”</td>
            <td className="p-2">
              shall have the meaning ascribed in section 12 of the Unfair
              Contract Terms Act 1977 Helpappy Limited
            </td>
          </tr>
          <tr className="bg-gray-50 dark:bg-gray-700 ">
            <td className="w-32 font-bold p-2">“Helpappy PA”</td>
            <td className="p-2">
              means a personal assistant engaged by Helpappy
            </td>
          </tr>
          <tr>
            <td className="w-32 font-bold p-2">“Services”</td>
            <td className="p-2">
              means the personal assistant services provided by Helpappy and the
              Helpappy PAs
            </td>
          </tr>
          <tr className="bg-gray-50 dark:bg-gray-700 ">
            <td className="w-32 font-bold p-2">“Signup Form”</td>
            <td className="p-2">
              means the online web page where the number of hours to be
              purchased is chosen, the price of each hour purchased is agreed,
              agreement to Helpappy’s terms and conditions is made and the
              Client’s initial purchase is made
            </td>
          </tr>
          <tr>
            <td className="w-32 font-bold p-2">“Website”</td>
            <td className="p-2">helpappy.com</td>
          </tr>
        </tbody>
      </table>

      <h2 className="font-bold text-lg mt-4 mb-2">3. THESE TERMS</h2>
      <p className="mb-2">
        3.1. These are the terms and conditions on which we supply our Services
        to you.
      </p>
      <p className="mb-2">
        3.2. Please read these terms carefully before you submit your order to
        us. These terms tell you who we are, how we will provide products to
        you, how you and we may change or end the contract, what to do if there
        is a problem and other important information.{" "}
      </p>
      <h2 className="font-bold text-lg mt-4 mb-2">
        4. INFORMATION ABOUT US AND HOW TO CONTACT US{" "}
      </h2>
      <p className="mb-2">
        4.1. We are Helpappy Limited a company registered in England and Wales.
        Our company registration number is 11034424 and our registered office is
        at 167 Uxbridge Road, London, United Kingdom, W7 3TH.
      </p>
      <p className="mb-2">
        4.2. You can contact us at the following email address:
        support@helpappy.com or at the above postal address.{" "}
      </p>
      <h2 className="font-bold text-lg mt-4 mb-2">5. ORDERING</h2>
      <p className="mb-2">
        5.1. All orders for Services shall be deemed to be an offer by the
        Client to purchase Services pursuant to these Terms and Conditions and
        are subject to acceptance by Helpappy. Helpappy may choose not to accept
        an order for any reason. Our acceptance of your order will take place
        when we write to you to accept it or start providing the Service
        (whichever is earlier), at which point a contract will come into
        existence between you and us. If we cannot accept your order or if we
        are unable to accept your order, we will inform you of this and will not
        charge you for the Service.
      </p>
      <h2 className="font-bold text-lg mt-4 mb-2">
        6. LIABILITY OF HELPAPPY AND OUR PAS
      </h2>
      <p className="mb-2">
        6.1. Helpappy is a personal assistant service. Our Helpappy PAs will
        assist you in finding goods and services from third parties (“Third
        Party Goods or Services”). When you decide to purchase Third Party Goods
        or Services, you enter into a new contract with that third party and you
        are doing so at your own risk. Although a Helpappy PA may suggest Third
        Party Goods or Services to you and may purchase these on your behalf,
        you agree that you have done your own due diligence on the goods and/or
        services and the terms attached to and have independently decided to
        purchase such goods or services. Helpappy does not warrant or guarantee
        the quality of any Third Party Goods or Service. Neither Helpappy nor
        any Helpappy PA is liable for:
      </p>
      <p className="ml-2 mb-2">
        6.1.1. the quality of such goods or services;{" "}
      </p>
      <p className="ml-2 mb-2">
        6.1.2. the goods or services not being to your satisfaction;
      </p>
      <p className="ml-2 mb-2">
        6.1.3. any failure in the delivery of a service; or
      </p>
      <p className="ml-2 mb-2">6.1.4. any failure of goods to arrive.</p>

      <p className="mb-2">
        6.2. Our Helpappy PAs will assist you with your life admin but you
        remain ultimately responsible for any life admin carried out on your
        behalf by a Helpappy PA. Neither Helpappy nor any Helpappy PA is liable
        for any direct or indirect losses that you incur as a result of an error
        made by a Helpappy or any Helpappy PA.
      </p>

      <p className="mb-2">
        6.3. Our Helpappy PAs can save you time by researching various life
        admin tasks for you e.g. finding you a holiday, nanny, tradesperson.
        However, we cannot guarantee that we will be able to find any of these
        things. Helpappy’s hourly rate is payable regardless of whether you end
        up booking that holiday or find a nanny etc.{" "}
      </p>

      <h2 className="font-bold text-lg mt-4 mb-2">7. PAYMENT AND PRICING</h2>
      <p className="mb-2">
        7.1. The price of the Services shall be that stipulated on the Signup
        form, or may be customised to the Client’s needs by agreement between
        Helpappy and the Client.
      </p>
      <p className="mb-2">
        7.2. Our invoices are payable upon receipt. If any payment is not paid
        on time or any payment is rejected or refused, the amount owing will be
        treated as overdue and Helpappy will be entitled immediately to cease or
        suspend the provision of the relevant Service until payment has been
        received.
      </p>
      <p className="mb-2">
        7.3. Interest on late payments will be charged once your invoice is 5
        working days overdue at a rate of 1% on the total amount due per day.
      </p>
      <p className="mb-2">
        7.4. Helpappy reserves the right to refer unpaid and overdue invoices to
        an accredited debt collector of Helpappy’s choosing, which may incur
        additional fees.
      </p>
      <p className="mb-2">
        7.5. Subscription fees are taken on the Client’s Bill day at the
        beginning of the monthly period. The Client’s subscription will
        automatically renew on the Client’s Bill day or following 100%
        utilisation of the Client’s Subscription (whichever date is earlier)
        unless you give written notice to Helpappy in accordance with clauses
        8.3 or 8.4 below.
      </p>
      <p className="mb-2">
        7.6. Fees for additional work requested by the Client are due prior to
        any additional work commencing and can be made via Stripe, credit or
        debit card or Direct Debit.
      </p>
      <p className="mb-2">
        7.7. Refunds are available solely at the discretion of Helpappy.
      </p>
      <p className="mb-2">
        7.8. The named account holder agrees to personally guarantee and
        underwrite all and any debts owed to Helpappy in the course of our
        relationship. By entering into this agreement you agree that we can
        recover all and any debts outstanding from you personally in the event
        that your trading entity, regardless of form, fails to settle the debts
        directly with Helpappy.
      </p>
      <h2 className="font-bold text-lg mt-4 mb-2">
        8. PERFORMANCE AND TERMINATION
      </h2>
      <p className="mb-2">
        8.1. Helpappy shall perform the Services with reasonable skill and care.
        However, Helpappy does not guarantee that the Services will be
        uninterrupted, secure or error-free or that any data generated, stored,
        transmitted or used via or in connection with the Services will be
        complete, accurate, secure, up to date, received or delivered correctly
        or at all.
      </p>
      <p className="mb-2">
        8.2. The Services shall continue indefinitely unless and until
        terminated in accordance with clauses 8.3 and 8.4.
      </p>
      <p className="mb-2">
        8.3. Either party may terminate this agreement (as regards some or all
        of the Services) for any reason by providing written notice.
      </p>
      <p className="mb-2">
        8.4. During the first 30 days, cancellation shall be deemed to take
        effect immediately upon receipt of the written notice. After the first
        30 days, cancellation shall be deemed to take effect 30 days after the
        Customer’s next Bill day following receipt by Helpappy of the written
        notice.
      </p>
      <p className="mb-2">
        8.5. Any payments due following cancellation shall remain payable.
      </p>
      <p className="mb-2">
        8.6. Should the Client terminate the account, account information will
        be required for account security purposes. After the end of the paid-for
        period, the Client will have no further access to the account and all
        data relating to the Client will be deleted in accordance with
        Helpappy’s{" "}
        <Link className="font-bold text-blue-500	" to="/privacy-policy">
          privacy policy
        </Link>
        .
      </p>
      <p className="mb-2">
        8.7. Helpappy may terminate this agreement (as regards some or all of
        the Services) or suspend some or all of the Services immediately on
        written notice if the Client breaches any term of this agreement, and
        any payment due remains payable and, if already paid, will be
        non-refundable.
      </p>
      <p className="mb-2">
        8.8. Helpappy may terminate this agreement (as regards some or all of
        the Services) or suspend some or all of the Services should the Client
        become financially insolvent.
      </p>
      <p className="mb-2">
        8.9. The Client may not use the Service to arrange or carry on any
        illegal or immoral activity.
      </p>
      <p className="mb-2">
        8.10. The Client agrees that Helpappy is not a recruitment agency or
        introducer and does not search or specifically select staff or third
        parties on behalf of the Client and therefore relevant recruitment
        agency laws and regulations do not apply to Helpappy.
      </p>
      <h2 className="font-bold text-lg mt-4 mb-2">9. RIGHTS OF HELPAPPY</h2>
      <p className="mb-2">
        9.1. Helpappy reserves the right to periodically update any prices shown
        on the Website, which cannot be guaranteed for any period of time.
        Helpappy shall make every effort to ensure any prices shown are correct
        at the point at which the Client places an order.
      </p>
      <p className="mb-2">
        9.2. If Helpappy updates the price paid by an existing Client, notice
        shall be provided to the email address held on record for the account no
        less than one full month prior to the change taking effect.
      </p>
      <p className="mb-2">
        9.3. Helpappy reserves the right to withdraw the Services from the
        Website at any time.
      </p>
      <p className="mb-2">
        9.4. Helpappy shall not be liable to anyone for withdrawing the Services
        from the Website or for refusing to process an order.
      </p>
      <h2 className="font-bold text-lg mt-4 mb-2">10. AGE OF CONSENT</h2>
      <p className="mb-2">
        10.1. Where the Services provided involve the purchase of articles which
        may only be purchased by persons of a certain age the Client will be
        asked when placing the order to declare that they are of the appropriate
        legal age to purchase the articles.
      </p>
      <p className="mb-2">
        10.2. If Helpappy discovers that the Client is not legally entitled to
        order certain Services or articles, Helpappy shall be entitled to cancel
        the order immediately, without notice.
      </p>
      <h2 className="font-bold text-lg mt-4 mb-2">
        11. CANCELLATION WHEN BUYING AS A CONSUMER
      </h2>
      <p className="mb-2">
        11.1. If purchasing as a Consumer the Client has the right to cancel the
        Contract, by notice in writing, at any time before seven working days
        has passed from the day after the Contract was made. If, however, the
        Supplier starts to perform its side of the Contract with the agreement
        of the Client before the Client exercises this right to cancel, the
        right to cancel is lost.
      </p>
      <h2 className="font-bold text-lg mt-4 mb-2">
        12. LIMITATION OF LIABILITY
      </h2>
      <p className="mb-2">
        12.1. Except as may be mandated by statute where the Client is dealing
        as a Consumer, in the event of any breach of these Terms and Conditions
        by Helpappy the remedies of the Client shall be limited to damages,
        which shall in no circumstances exceed the regular monthly subscription
        price of the Services paid by the Client in the preceding 3 months and
        Helpappy shall under no circumstances be liable for any indirect,
        incidental or consequential loss or damage whatever.
      </p>
      <p className="mb-2">
        12.2. To the extent permitted by law, Helpappy shall not be liable to
        the Client save as expressly provided for in this agreement and shall
        have no other obligations, duties or liabilities whatsoever in contract,
        tort or otherwise to the Client, specifically:
      </p>
      <p className="mb-2">
        12.2.1. Helpappy will not be responsible for any damages the Client’s
        business may suffer;
      </p>
      <p className="mb-2">
        12.2.2. Helpappy makes no warranties of any kind, expressed or implied
        for the services provided; and
      </p>
      <p className="mb-2">
        12.2.3. Helpappy disclaims any warranty or merchantability or fitness
        for a particular purpose.
      </p>

      <h2 className="font-bold text-lg mt-4 mb-2">13. WAIVER</h2>
      <p className="mb-2">
        13.1. No waiver by Helpappy (whether express or implied) in enforcing
        any of its rights under this agreement shall prejudice its rights to do
        so in the future.
      </p>
      <h2 className="font-bold text-lg mt-4 mb-2">14. FORCE MAJEURE</h2>
      <p className="mb-2">
        14.1. Helpappy shall not be liable for any delay or failure to perform
        any of its obligations if the delay or failure results from events or
        circumstances outside its reasonable control, including but not limited
        to acts of God, strikes, lock outs, accidents, war, fire, flood or
        failure of any communications, telecommunications or computer system,
        and Helpappy shall be entitled to a reasonable extension of its
        obligations.
      </p>
      <h2 className="font-bold text-lg mt-4 mb-2">15. SEVERANCE</h2>
      <p className="mb-2">
        15.1. If any term or provision of these Terms and Conditions is held
        invalid, illegal or unenforceable for any reason by any court of
        competent jurisdiction such provision shall be severed and the remainder
        of the provisions hereof shall continue in full force and effect as if
        these Terms and Conditions had been agreed with the invalid illegal or
        unenforceable provision eliminated.
      </p>
      <h2 className="font-bold text-lg mt-4 mb-2">
        16. CHANGES TO TERMS AND CONDITIONS
      </h2>
      <p className="mb-2">
        16.1. Helpappy shall be entitled to alter these Terms and Conditions at
        any time but this right shall not affect the existing Terms and
        Conditions accepted by the Client upon making a purchase until the
        Client’s monthly renewal of the Services occurs.
      </p>
      <p className="mb-2">
        16.2. Monthly renewal by the Client of the Services will be deemed
        acceptance of Helpappy’s then current Terms and Conditions.
      </p>

      <h2 className="font-bold text-lg mt-4 mb-2">17. OTHER IMPORTANT TERMS</h2>
      <p className="mb-2">
        17.1. We may transfer this agreement to someone else. We may transfer
        our rights and obligations under these terms to another organisation.{" "}
      </p>
      <p className="mb-2">
        17.2. The Client will notify Helpappy immediately if the security of
        their account has been compromised.
      </p>
      <p className="mb-2">
        17.3. The Client or any of its officers, employees or associates may not
        at any time (either during or within one year of the last provision of
        service by Helpappy to the Client contract with, employ, reward or pay
        (either directly or indirectly) any Helpappy PA (who has supplied the
        Services to the Client in the year preceding the last provision of
        service by Helpappy to the Client). Should the Client or any of its
        officers, employees or associates breach this term, a fee of the greater
        of £3,500 or 12.5% of Helpappy’s annual total cost to the Client will
        become payable to Helpappy immediately. Helpappy will invoice the Client
        for this fee if it has a reasonable suspicion that the Client is in
        breach of this agreement, if the Client provides evidence in writing
        that this agreement has not been breached Helpappy will cancel the
        invoice.
      </p>
      <p className="mb-2">
        17.4. Each of the provisions in these Terms and Conditions are separate
        and severable and, in the event of any such provision being determined
        as being unenforceable in whole or in part for any reason, such
        unenforceability shall not affect the enforceability of the remaining
        provisions or, in the case of part of a provision being unenforceable,
        the remainder of that provision.
      </p>
      <p className="mb-2">
        17.5. No term of these Terms and Conditions are enforceable against
        Helpappy under the Contracts (Rights of Third Parties) Act 1999 by a
        person who is not a party to these Terms and Conditions.
      </p>
      <p className="mb-2">
        17.6. These Terms and Conditions constitute the whole contract between
        Helpappy and the Client. The Client confirms that, except as provided in
        these Terms and Conditions, you have not relied on any representation,
        warranty, assurance, covenant, indemnity, undertaking or commitment
        which is not contained in these Terms and Conditions and, without
        prejudice to any liability for fraudulent misrepresentation or
        fraudulent misstatement;
      </p>
      <p className="mb-2 ml-2">
        17.6.1. the only rights or remedies in relation to any representation,
        warranty, assurance, covenant, indemnity, undertaking or commitment
        given or action taken in connection with these Terms and Conditions are
        contained in these Terms and Conditions; and{" "}
      </p>
      <p className="mb-2 ml-2">
        17.6.2. for the avoidance of doubt and without limitation, you have no
        right or remedy (whether by way of a claim for contribution or
        otherwise) in tort (including negligence) or for misrepresentation
        (whether negligent or otherwise, and whether made prior to, and/or in,
        these Terms and Conditions).
      </p>
      <p className="mb-2">
        17.7. These Terms and Conditions shall be governed by and construed in
        accordance with English law and the parties submit to the exclusive
        jurisdiction of the English courts.
      </p>
    </div>
  </Layout>
)

export default SecondPage
